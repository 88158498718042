export const projectsList = [
  {
    name: 'nss-digital-space',
    date: '2024',
    slug: 'nss-digital-space',
    tagline: 'The source of this website.',
    highlight: true,
    // url: 'https://www.taniarascia.com/projects',
    // writeup: 'https://www.taniarascia.com/projects'
  },
  {
    name: 'Reddit Hole',
    date: '2024',
    slug: 'reddit-hole',
    tagline: 'Automatic Reddit scraper and video creator',
    highlight: true,
  },
  {
    name: 'Image Caption Generator',
    date: '2023',
    slug: 'image-caption-generator',
    tagline: 'Image captioning model with Resnet50 encoder and LSTM decoder.',
    highlight: true,
    url: 'https://huggingface.co/spaces/nssharmaofficial/ImageCaption'
  },
  {
    name: 'Logo Removal',
    date: '2023',
    slug: 'logo-removal-gan',
    tagline: 'TV-channel logo removal on images using GAN.',
    highlight: true,
  },
  {
    name: 'Review Sentiment Classifier',
    date: '2022',
    slug: 'review-sentiment-classifier',
    tagline: 'IMDB review classification using LSTM.',
    highlight: false,
  },
  {
    name: 'Red Eye Effect Classifier',
    date: '2024',
    slug: 'red-eye-effect-classifier',
    tagline: 'CNN classifier of human eye images to distinguish images with the red eye effect.',
    highlight: false,
    url: 'https://huggingface.co/spaces/nssharmaofficial/RedEyeClassifier'
  },
  {
    name: 'Next Purchase Order Prediction',
    date: '2022',
    slug: 'market-basket-analysis',
    tagline: 'Instacart market basket analysis using Pandas and next purchase order prediction by XGBoost.',
    highlight: false,
  },
  {
    name: 'Fake News Classifier',
    date: '2022',
    slug: 'fake-news-classifier',
    tagline: 'Article classification using BoW and logistic regression.',
    highlight: false,
  },
  {
    name: 'Skin Cancer Classifier',
    date: '2022',
    slug: 'skin-cancer-cnn',
    tagline: 'Classification performance comparison of Resnet50, Alexnet, Squeezenet and custom net.',
    highlight: false,
  },
  {
    name: 'K-Means in CUDA',
    date: '2021',
    slug: 'kmeans-in-cuda',
    tagline: 'K-means algorithm parallelized in CUDA.',
    highlight: false,
  },
  {
    name: 'Discrete Event Systems Simulation',
    date: '2021',
    slug: 'discrete-event-systems-sim',
    tagline: 'Simulation of discrete event systems in MATLAB.',
    highlight: false,
  },
]